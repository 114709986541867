
import { Vue, Component, Ref } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { NavigationGuardNext, Route } from 'vue-router';
import { fireGoogleTag } from '@/utils/google-tag-manager-helpers';
import { selectTheme } from '@/utils/styling';
import Cookies from 'js-cookie';
import CrosshairIcon from 'vue-feather-icons/icons/CrosshairIcon';
import MapPinIcon from 'vue-feather-icons/icons/MapPinIcon';
import SearchIcon from 'vue-feather-icons/icons/SearchIcon';
import GoogleAutocomplete from '@/components/shared/GoogleAutocomplete.vue';
import InfoBanner from '@/components/shared/InfoBanner.vue';

const namespace = 'geolocation';

@Component<GeolocationSearch>({
	components: {
		GoogleAutocomplete,
		CrosshairIcon,
		MapPinIcon,
		InfoBanner,
		SearchIcon
	}
})
export default class GeolocationSearch extends Vue {
	@Action('geolocationSearch', { namespace }) private geolocationSearch!: () => Promise<GeolocationSearchResponse>;
	@Action('autocompleteSearch', { namespace }) private autocompleteSearch!: (payload: { place: AutocompletePrediction, sessionToken: {} }) => Promise<GeolocationSearchResponse>;
	@Action('setGeolocationPayload', { namespace }) private setGeolocationPayload!: (payload: GeolocationPayload) => void;
	@Action('setGeolocationPermission', { namespace }) private setGeolocationPermission!: (permission: string) => void;
	@Action('setGeolocationResults', { namespace }) private setGeolocationResults!: (results: GeolocationResult[] | null) => void;
	@Action('setShowDistance', { namespace }) private setShowDistance!: (showDistance: string[]) => void;
	@Action('resetRestaurantState', { namespace: 'restaurant' }) private resetRestaurantState!: () => void;
	@Getter('getGeolocationPayload', { namespace }) private geolocationPayload!: GeolocationPayload;
	@Getter('getGeolocationResults', { namespace }) private geolocationResults!: GeolocationSearchResponse;
	@Getter('getCountry', { namespace }) private country!: string | string[];
	@Getter('getGeolocationPermission', { namespace }) private geolocationPermission!: string;
	@Getter('getShowDistance', { namespace }) private showDistance!: boolean;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant | null;
	@Ref('address') readonly addressRef!: InstanceType<typeof GoogleAutocomplete>;

	private loading: boolean = false;
	private geolocationError: string = '';
	private autoRedirect: boolean = true;

	private get bowleroLogo(): string {
		return `${process.env.VUE_APP_IMAGE_BUCKET}/bowlero/bowlero-logo.png`;
	}

	private get background(): string {
		return `${process.env.VUE_APP_IMAGE_BUCKET}/bowlero/search-background.png`;
	}

	/**
	 * Check if user is changing location (coming from the menu page) and set the autoRedirect flag
	 * Prevents the user from being automatically redirected to the same location if geolocation was used, when changing location
	 *
	 * @param {Route} to
	 * @param {Route} from
	 * @param {NavigationGuardNext} next
	 * @return {void}
	 */
	private beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
		if (from.name === 'Menu' || from.name ==='ComingSoon') {
			next(vm => {
				(vm as GeolocationSearch).autoRedirect = false;
			});
		}
		else {
			next();
		}
	}

	private created(): void {
		document.documentElement.classList.remove('modal-open');
		selectTheme('bowlero');
		fireGoogleTag({ name: 'geolocationPage' });

		// If there is a restaurant in the store remove it
		// So that the restaurant will be fetched again when selecting a new location
		if (this.restaurant) {
			this.resetRestaurantState();
		}
	}

	private async mounted(): Promise<void> {
		// Check if user has selected a location in the last day and redirect to that location
		const currentLocationCookie = Cookies.get('currentLocation');
		if (currentLocationCookie) {
			this.loading = true;
			this.redirectToLocation(undefined, currentLocationCookie);
		}
		else {
			await this.searchFromPreviousLocation();

			// Fill the input with name or address from the store if it exists
			// If there is an exact location match use that location's name, if the name is not part of the address
			if (this.geolocationResults?.redirectLocation && this.geolocationPayload.name && !this.geolocationPayload.address!.includes(this.geolocationPayload.name)) {
				this.addressRef.update(this.geolocationPayload.name);
			}
			else if (this.geolocationPayload.address) {
				this.addressRef.update(this.geolocationPayload.address);
			}

			// Don't geolocate if the user is changing location, user has to use "use my location" button
			if (this.autoRedirect) {
				await this.checkGeolocationPermissions();
			}
		}
	}
	/**
	 * Perform a location search using the previous location cookie
	 *
	 * @return {Promise<void>}
	 */
	private async searchFromPreviousLocation(): Promise<void> {
		const previousLocationCookie = Cookies.get('previousLocation');
		if (previousLocationCookie) {
			try {
				this.loading
				const parsedLocation = JSON.parse(previousLocationCookie);
				this.autoRedirect = false;
				this.setGeolocationPayload({
					...this.geolocationPayload,
					lat: +parsedLocation.latitude,
					lng: +parsedLocation.longitude,
					name: parsedLocation.name,
					address: parsedLocation.address,
					gmapPlaceId: parsedLocation.gmapPlaceId
				});
				await this.geolocationSearch();
				this.loading = false;
			}
			catch (error) {
				this.loading = false;
				this.geolocationError = this.$t('geolocation.errors.location_search_error');
				fireGoogleTag({ name: 'errorFetchingLocations' });
			}
		}
	}

	/**
	 * Check geolocation permissions and set watch for change in permissions
	 *
	 * @return {Promise<void>}
	 */
	private async checkGeolocationPermissions(): Promise<void> {
		if ('permissions' in navigator) {
			const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
			this.setGeolocationPermissionAndSearch(permissionStatus.state as GeolocationPermissionState);

			permissionStatus.onchange = () => {
				this.setGeolocationPermissionAndSearch(permissionStatus.state as GeolocationPermissionState);
			}
		}
	}

	/**
	 * Set geolocation permission and call the geolocateAndSearch method
	 *
	 * @param {GeolocationPermissionState} state
	 * @return {void}
	 */
	private setGeolocationPermissionAndSearch(state: GeolocationPermissionState): void {
		this.setGeolocationPermission(state);

		// If the user has granted permission, set loading to true immediately because the user won't be prompted to enable geolocation
		if (this.geolocationPermission === 'granted') {
			this.loading = true;
			this.geolocateAndSearch();
		}
		else if (this.geolocationPermission === 'prompt') {
			this.geolocateAndSearch();
		}
		else {
			this.geolocationError = this.$t('geolocation.errors.geolocation_denied');
		}
	}

	/**
	 * Get address data from the Google Autocomplete and call the location search
	 *
	 * @param {AutocompletePrediction} payload.place
	 * @param {Object} payload.sessionToken
	 * @return {Promise<void>}
	 */
	private async handleGooglePlaceSearch(payload: { place: AutocompletePrediction, sessionToken: {} }): Promise<void> {
		this.loading = true;
		try {
			fireGoogleTag({ name: 'addresSubmit', detail: 'manual' });
			fireGoogleTag({ name: 'searchTerm', specifier: payload.place.types.join(','), detail: payload.place.description });
			this.setGeolocationPayload({
				...this.geolocationPayload,
				address: payload.place.description,
				gmapPlaceId: payload.place.place_id
			});
			this.setShowDistance(payload.place.types);

			await this.autocompleteSearch(payload);
			const { locations, placeDetails, redirectLocation } = this.geolocationResults;

			// Remy returns a redirectLocation if location searched has a matching place id, name or address of a location in our db
			// So we redirect to that location instead of showing the search results
			if (redirectLocation) {
				fireGoogleTag({ name: 'redirectedToLocation', specifier: 'locationMatch', detail: redirectLocation.name });
				this.redirectToLocation(redirectLocation);
			}
			else {
				if (placeDetails) {
					this.setGeolocationPayload({
						...this.geolocationPayload,
						lat: placeDetails.geometry.location.lat(),
						lng: placeDetails.geometry.location.lng(),
						name: placeDetails.name,
						address: placeDetails.formatted_address
					});
					// If place details are returned, then that mean a call to get place details was made so we need to reset the session token
					this.addressRef.resetSessionToken();
				}
				this.loading = false;

				if (!locations.length) {
					fireGoogleTag({ name: 'noResultsFound', detail: this.geolocationPayload.address });
				}
			}
		}
		catch (error) {
			this.loading = false;
			this.geolocationError = this.$t('geolocation.errors.location_search_error');
			fireGoogleTag({ name: 'errorFetchingLocations' });
		}
	}


	/**
	 * Enable geolocation, get the current position and geocode, then call the location search
	 *
	 * @return {Promise<void>}
	 */
	private async geolocateAndSearch(): Promise<void> {
		// Check if geolocation is supported by the browser
		if ('geolocation' in navigator) {
			// Get the current position
			navigator.geolocation.getCurrentPosition(async (position) => {
				this.setGeolocationPermission('granted');
				this.loading = true;

				try {
					this.setGeolocationPayload({
						...this.geolocationPayload,
						lat: position.coords.latitude,
						lng: position.coords.longitude
					});

					fireGoogleTag({ name: 'addresSubmit', detail: 'auto' });
					await this.geolocationSearch();
					const { geocodingResults, locations, redirectLocation } = this.geolocationResults;

					if (redirectLocation) {
						fireGoogleTag({ name: 'redirectedToLocation', specifier: 'locationNearby', detail: redirectLocation.name });
						this.redirectToLocation(redirectLocation);
					}
					else {
						// Update the address input and the store state with geocoding results
						if (geocodingResults) {
							this.addressRef.update(geocodingResults.formatted_address);
							this.setGeolocationPayload({
								...this.geolocationPayload,
								name: geocodingResults.name,
								address: geocodingResults.formatted_address,
								gmapPlaceId: geocodingResults.place_id
							});

						}
						this.loading = false;
						if (!locations.length) {
							fireGoogleTag({ name: 'noResultsFound', detail: geocodingResults.formatted_address });
						}
					}
				}
				catch (error) {
					this.loading = false;
					this.geolocationError = this.$t('geolocation.errors.geolocation_failed');
					fireGoogleTag({ name: 'errorFetchingLocations' });
				}
			},
			() => {
				this.loading = false;
				this.setGeolocationPermission('denied');
				// If the user denies geolocation, set the results to null so the error message is displayed
				// Catches a pretty specific edge case where the user accepts geolocation temporarily, but denies when prompted again
				this.setGeolocationResults(null);
				this.geolocationError = this.$t('geolocation.errors.geolocation_denied');
				fireGoogleTag({ name: 'autoLocateDenied' });
			});
		}
		else {
			this.loading = false;
			this.setGeolocationPermission('denied');
			this.geolocationError = this.$t('geolocation.errors.geolocation_unsupported');
			fireGoogleTag({ name: 'geolocationNotSupported' });
		}
	}

	/**
	 * Set cookie for selected location and redirect to the location page
	 * Current location is set to expire in 1 day, and will automatically redirect to that location
	 * Previous location is set to expire in 15 days, and will be used to autofill the address input and search for locations
	 *
	 * @param {string} location
	 * @return {void}
	 */
	private redirectToLocation(location?: GeolocationResult, slug?: string): void {
		let redirectSlug = location ? location.slug : slug;
		// If slug is provided it means that the current location cookie is being used to redirect, so don't set a new previous location cookie
		if (slug) {
			Cookies.set('currentLocation', slug, { expires: 1 });
		}
		else if (location) {
			Cookies.set('currentLocation', location.slug, { expires: 1 });
			Cookies.set('previousLocation', JSON.stringify(location), { expires: 15 });
		}
		this.$router.push({ path: `/${redirectSlug}`, query: { genericTableLocation: 'true', ...this.$route.query }}).catch(() => {});
	}

	/**
	 * Fire Google Tag Manager event when a location card is clicked, then redirect
	 *
	 * @param {GeolocationResult} result
	 * @param {number} index
	 * @return {void}
	 */
	private handleLocationClick(location: GeolocationResult, index: number): void {
		fireGoogleTag({ name: 'clickLocationCard', detail: (index + 1).toString() })
		this.redirectToLocation(location);
	}
}
